import React from 'react'
import Icon from 'components/utils/Icon'

const IconAndText = (props) => {

  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundColor: 'white',
      borderRadius: 5,
      margin: 10
    }}>
      <span><b>{props.text}</b></span><Icon name={props.name} color={props.color} onClick={props.onClick} />
    </div>
  )

}

export default IconAndText
