import React, { useEffect, useRef } from 'react'
import IconBig from 'components/utils/IconBig'

const CustomTools = (props) => {

  let style = props.style

  let styleSelected = '2px solid dodgerblue'

  const canvasLineSolid = useRef(null)
  const canvasLineDashS = useRef(null)
  const canvasLineDashM = useRef(null)
  const canvasLineDashL = useRef(null)
  const canvasLineDashDot = useRef(null)
  const canvasLineDashDot3 = useRef(null)

  const drawStyleLinePattern = (ctx,pattern) => {

    ctx.strokeStyle = "#000000FF";
    ctx.lineJoin = "round";
    ctx.lineWidth = "100";

    ctx.beginPath();
    ctx.setLineDash(pattern);
    ctx.moveTo(0, 0);
    ctx.lineTo(300, 0);
    ctx.stroke();

  }

  useEffect(() => {

    drawStyleLinePattern(canvasLineSolid.current.getContext('2d'),[]);
    drawStyleLinePattern(canvasLineDashS.current.getContext('2d'),[1, 1]);
    drawStyleLinePattern(canvasLineDashM.current.getContext('2d'),[10, 10]);
    drawStyleLinePattern(canvasLineDashL.current.getContext('2d'),[20, 5]);
    drawStyleLinePattern(canvasLineDashDot.current.getContext('2d'),[15, 3, 3, 3]);
    drawStyleLinePattern(canvasLineDashDot3.current.getContext('2d'),[20, 3, 3, 3, 3, 3, 3, 3]);

  }, [])

  return (
    <div>

      {props.who === 'presets' ?

        <div style={{display: 'inline-block'}}>
          <label>Type</label><br/>
          <select className='select' pattern=".{1,}" name='typeId' onInput={props.validate} defaultValue={style.typeId} required>
            <option value=""></option>
            {props.listOfPresetTypes}
          </select>
        </div> : null

      }

      {props.who === 'drawings' ?

        <div style={{display: 'inline-block'}}>
          <label>Elev.</label><br/>
          <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='elev' onInput={props.validate} placeholder='Optional' defaultValue={style.elev} required />
        </div> : null

      }

      {props.who === 'presets' || props.who === 'drawings' ?

        <div>
          <label>Description
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={props.validate} defaultValue={style.description} required></textarea>
          </label>
        </div> : null

      }

      <div className="plansdraw_style_header border_bottom">

          <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Tools</i></p>

      </div>

      <div className="border_bottom">

        <IconBig name='create' color='black' id="planspencil" onClick={props.styleTool} border={style.tool === 'planspencil' ? styleSelected : 'none'} />

        <button className="iconBig" data-id="plansmarker" onClick={props.styleTool} style={{border: style.tool === 'plansmarker' ? styleSelected : 'none'}}><img src="icons/marker.png" data-id="plansmarker" /></button>

        <IconBig name='chat' color='black' id="planscomment" onClick={props.styleTool} border={style.tool === 'planscomment' ? styleSelected : 'none'} />

        <button className="iconBig" data-id="plansbe" onClick={props.styleTool} style={{border: style.tool === 'plansbe' ? styleSelected : 'none'}}><img src="icons/be.png" data-id="plansbe" /></button>

        <button className="iconBig" data-id="planssurveypt" onClick={props.styleTool} style={{border: style.tool === 'planssurveypt' ? styleSelected : 'none'}}><img src="icons/surveypt.png" data-id="planssurveypt" /></button>

        <button className="iconBig" data-id="attitude" onClick={props.styleTool} style={{border: style.tool === 'attitude' ? styleSelected : 'none'}}><img src="icons/attitude.png" data-id="attitude" /></button>

        <IconBig name='arrow_downward' color='black' id="plansarrow" onClick={props.styleTool} border={style.tool === 'plansarrow' ? styleSelected : 'none'} />

        <button className="iconBig" data-id="plansslope" onClick={props.styleTool} style={{fontSize: 50, border: style.tool === 'plansslope' ? styleSelected : 'none'}}>Y</button>

        <button className="iconBig" data-id="planstext" onClick={props.styleTool} style={{fontSize: 20, border: style.tool === 'planstext' ? styleSelected : 'none'}}><b data-id="planstext">ABC</b></button>

        <IconBig name='add_circle_outline' color='black' id="point" onClick={props.styleTool} border={style.tool === 'point' ? styleSelected : 'none'} />

      </div>

      <div className="plansdraw_style_header border_bottom">

          <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Colors</i></p>

      </div>

      <div className="border_bottom">

        <IconBig name='fiber_manual_record' color='#000000' id='#000000' onClick={props.styleColor} border={style.color.substr(0,7) === '#000000' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#ff6347' id='#ff6347' onClick={props.styleColor} border={style.color.substr(0,7) === '#ff6347' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#ffa500' id='#ffa500' onClick={props.styleColor} border={style.color.substr(0,7) === '#ffa500' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#ffff00' id='#ffff00' onClick={props.styleColor} border={style.color.substr(0,7) === '#ffff00' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#1e90ff' id='#1e90ff' onClick={props.styleColor} border={style.color.substr(0,7) === '#1e90ff' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#3cb371' id='#3cb371' onClick={props.styleColor} border={style.color.substr(0,7) === '#3cb371' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#9400d3' id='#9400d3' onClick={props.styleColor} border={style.color.substr(0,7) === '#9400d3' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='#000000' id='#000000' onClick={props.styleColorOther}
          border={
            style.color !== '' &&
            style.color.substr(0,7) !== '#000000' &&
            style.color.substr(0,7) !== '#ff6347' &&
            style.color.substr(0,7) !== '#ffa500' &&
            style.color.substr(0,7) !== '#ffff00' &&
            style.color.substr(0,7) !== '#1e90ff' &&
            style.color.substr(0,7) !== '#3cb371' &&
            style.color.substr(0,7) !== '#9400d3' ? styleSelected : 'none'
          }
          class='colorRainbow'
        />

      </div>

      <div className="plansdraw_style_header border_bottom">

          <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Thickness</i></p>

      </div>

      <div className="plansdraw_style_width border_bottom">

        <IconBig name='fiber_manual_record' color='black' fontSize={4} id='2.5' onClick={props.styleWidth} border={style.width === '2.5' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={8} id='5' onClick={props.styleWidth} border={style.width === '5' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={12} id='7.5' onClick={props.styleWidth} border={style.width === '7.5' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={16} id='10' onClick={props.styleWidth} border={style.width === '10' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={20} id='12.5' onClick={props.styleWidth} border={style.width === '12.5' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={24} id='15' onClick={props.styleWidth} border={style.width === '15' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={28} id='17.5' onClick={props.styleWidth} border={style.width === '17.5' ? styleSelected : 'none'} />
        <IconBig name='fiber_manual_record' color='black' fontSize={32} id='20' onClick={props.styleWidth} border={style.width === '20' ? styleSelected : 'none'} />

      </div>

      <div className="plansdraw_style_header border_bottom">

          <p className="plansdraw_style_header-text" style={{margin: 5}}><i>Line Type</i></p>

      </div>

      <div>

        <button className="styleLine" style={{border: style.lineName === 'solid' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='solid' data-id=""><canvas className="canvasLine" ref={canvasLineSolid} data-name='solid' data-id=""></canvas></button>
        <button className="styleLine" style={{border: style.lineName === 'dashS' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='dashS' data-id="5, 5"><canvas className="canvasLine" ref={canvasLineDashS} data-name='dashS' data-id="5, 5"></canvas></button>
        <button className="styleLine" style={{border: style.lineName === 'dashM' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='dashM' data-id="20, 20"><canvas className="canvasLine" ref={canvasLineDashM} data-name='dashM' data-id="20, 20"></canvas></button>
        <button className="styleLine" style={{border: style.lineName === 'dashL' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='dashL' data-id="40, 20"><canvas className="canvasLine" ref={canvasLineDashL} data-name='dashL' data-id="40, 20"></canvas></button>
        <button className="styleLine" style={{border: style.lineName === 'dashDot' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='dashDot' data-id="30, 10, 6, 10"><canvas className="canvasLine" ref={canvasLineDashDot} data-name='dashDot' data-id="30, 10, 6, 10"></canvas></button>
        <button className="styleLine" style={{border: style.lineName === 'dashDot3' ? styleSelected : 'none'}} onClick={props.styleLine} data-name='dashDot3' data-id="40, 10, 6, 10, 6, 10, 6, 10"><canvas className="canvasLine" ref={canvasLineDashDot3} data-name='dashDot3' data-id="40, 10, 6, 10, 6, 10, 6, 10"></canvas></button>

      </div>

    </div>
  )
}

export default CustomTools
