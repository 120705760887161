import React, {useState, useEffect, useRef} from 'react'
import Icon from 'components/utils/Icon'
import CircleButton from 'components/utils/CircleButton'
import AddButton from 'components/utils/AddButton'
import Modal from 'components/utils/Modal'
import ViewLocation from 'components/utils/ViewLocation'
import Job from 'components/menu/Job'
import SearchBar from 'components/utils/SearchBar'

import { formatDateTime, formatDateYMD, formatTime, getLocation, replaceStr } from 'scripts/common'

const Mileage = (props) => {

  const googleRef = useRef(null)
  const mapRef = useRef(null)
  const markerRef = useRef([])

  const [fetchedData, setFetchedData] = useState([])
  const [isModal, setIsModal] = useState({
    add: false,
    edit: false,
    origin: false,
    dest: false,
    history: false
  })

  const isChanged = useRef(false)
  const [searchValue, setSearchValue] = useState('')

  const [isValidated, setIsValidated] = useState({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    origin: '',
    dest: '',
    originLat: '',
    originLng: '',
    destLat: '',
    destLng: '',
    originOD: '',
    destOD: '',
    mileageDate: '',
    distance: '',
    duration: '',
    description: ''
  })

  const clearIsValidated = () => setIsValidated({
    entryby: '',
    entrytime: null,
    entrylat: '',
    entrylng: '',
    entrydevice: '',
    modby: '',
    modtime: null,
    modlat: '',
    modlng: '',
    moddevice: '',
    id: null,
    origin: '',
    dest: '',
    originLat: '',
    originLng: '',
    destLat: '',
    destLng: '',
    originOD: '',
    destOD: '',
    mileageDate: '',
    distance: '',
    duration: '',
    description: ''
  })

  const fetchData = () => {

    fetch('/api/selectMileages', {
      method: 'post',
      headers: {
        'Accept': 'application/json, text/plain, */*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        by: props.user.username,
        time: formatDateTime(new Date())
      })
    })
    .then(res=>res.json())
    .then(
      (result) => {
        //console.log('result: ' + JSON.stringify(result))
        setFetchedData(result)
      },
      (error) => {
        console.log('Error: selectMileages --> ' + error)
      }
    )

  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (isModal.add || isModal.edit) initMap()
  }, [isModal.add, isModal.edit, isValidated.origin, isValidated.dest])

  const validate = (event) => {
    let name = event.target.getAttribute('name')
    let state = event.target.reportValidity()
    let type = event.target.type
    let value = type === 'checkbox' ? event.target.checked : event.target.value

    setIsValidated(prevState => ({...prevState, [name]: state ? value : null}))
  }

  const selectRow = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')
    let i = td[0].textContent

    if (i === '' || i === null) {
      alert('Error: data index not found. Contact an admin.')
    } else {

      setIsValidated(prevState => ({...prevState,
        entryby: fetchedData[i].entryby,
        entrytime: fetchedData[i].entrytime,
        entrylat: fetchedData[i].entrylat,
        entrylng: fetchedData[i].entrylng,
        entrydevice: fetchedData[i].entrydevice,
        modby: fetchedData[i].modby,
        modtime: fetchedData[i].modtime,
        modlat: fetchedData[i].modlat,
        modlng: fetchedData[i].modlng,
        moddevice: fetchedData[i].moddevice,
        id: fetchedData[i].id,
        origin: fetchedData[i].origin,
        dest: fetchedData[i].dest,
        originLat: fetchedData[i].originLat,
        originLng: fetchedData[i].originLng,
        destLat: fetchedData[i].destLat,
        destLng: fetchedData[i].destLng,
        originOD: fetchedData[i].originOD,
        destOD: fetchedData[i].destOD,
        mileageDate: fetchedData[i].mileageDate,
        distance: fetchedData[i].distance,
        duration: fetchedData[i].duration,
        description: fetchedData[i].description
      }))
      openEdit()

    }

  }

  const changedData = () => isChanged.current = true

  const addMileage = () => {

    //console.log(`isValidated: ${JSON.stringify(isValidated)}`)

    if (isValidated.originLat === '' || isValidated.originLat === null || isValidated.originLng === '' || isValidated.originLng === null) {
      alert('Please select an origin')
    } else if (isValidated.destLat === '' || isValidated.destLat === null || isValidated.destLng === '' || isValidated.destLng === null) {
      alert('Please select a destination')
    } else {

      getLocation(function(latlng){

        fetch('/api/addMileage', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            origin: isValidated.origin,
            dest: isValidated.dest,
            originLat: isValidated.originLat,
            originLng: isValidated.originLng,
            destLat: isValidated.destLat,
            destLng: isValidated.destLng,
            originOD: isValidated.originOD,
            destOD: isValidated.destOD,
            mileageDate: isValidated.mileageDate,
            distance: isValidated.distance,
            duration: isValidated.duration,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {

            props.updateParent()
            fetchData() // i need the id if edited
            isChanged.current = false
            closeModal()
            //alert('Added.')

          },
          (error) => {

            alert('Error: could not add Mileage. Contact and admin.')
            console.log('Error: addMileage --> ' + error)
          }
        )

      })

    }

  }

  const editMileage = () => {

    if (isValidated.originLat === '' || isValidated.originLat === null || isValidated.originLng === '' || isValidated.originLng === null) {
      alert('Please select an origin')
    } else if (isValidated.destLat === '' || isValidated.destLat === null || isValidated.destLng === '' || isValidated.destLng === null) {
      alert('Please select a destination')
    } else {

      //console.log(`isValidated: ${JSON.stringify(isValidated)}`)

      getLocation(function(latlng){

        fetch('/api/editMileage', {
          method: 'post',
          headers: {
            'Accept': 'application/json, text/plain, */*',
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            by: props.user.username,
            time: formatDateTime(new Date()),
            lat: latlng.lat,
            lng: latlng.lng,
            device: props.user.device,
            id: isValidated.id,
            origin: isValidated.origin,
            dest: isValidated.dest,
            originLat: isValidated.originLat,
            originLng: isValidated.originLng,
            destLat: isValidated.destLat,
            destLng: isValidated.destLng,
            originOD: isValidated.originOD,
            destOD: isValidated.destOD,
            mileageDate: isValidated.mileageDate,
            distance: isValidated.distance,
            duration: isValidated.duration,
            description: replaceStr(isValidated.description)
          })
        })
        .then(res=>res.json())
        .then(
          (result) => {
            //console.log('result: ' + JSON.stringify(result))

            setFetchedData(fetchedData.map(data =>
              data.id === isValidated.id ?
              {...data,
                modby: props.user.username,
                modtime: formatDateTime(new Date()),
                modlat: latlng.lat,
                modlng: latlng.lng,
                moddevice: props.user.device,
                origin: isValidated.origin,
                dest: isValidated.dest,
                originLat: isValidated.originLat,
                originLng: isValidated.originLng,
                destLat: isValidated.destLat,
                destLng: isValidated.destLng,
                distance: isValidated.distance,
                duration: isValidated.duration,
                description: isValidated.description
              } :
              data
            ))

            props.updateParent()
            isChanged.current = false
            closeModal()
            //alert('Updated')

          },
          (error) => {

            alert('Error: could not edit Mileage. Contact and admin.')
            console.log('Error: editMileage --> ' + error)
          }
        )

      })

    }

  }

  const deleteMileage = () => {

    if (window.confirm('If you proceed, this will be deleted. Proceed?')) {

      fetch('/api/deleteMileage', {
        method: 'post',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          id: isValidated.id
        })
      })
      .then(res=>res.json())
      .then(
        (result) => {
          //console.log('result: ' + JSON.stringify(result))

          props.updateParent()
          setFetchedData(fetchedData.filter(data => data.id !== isValidated.id))
          isChanged.current = false
          closeModal()
          //alert('Deleted.')

        },
        (error) => {

          alert('Error: could not delete Mileage. Contact and admin.')
          console.log('Error: deleteMileage --> ' + error)
        }
      )

    }

  }

  const search = (e) => {
    let value = e.target.value
    setSearchValue(value)
  }

  const clearSearch = () => {
    document.getElementById('searchInput').value = ''
    setSearchValue('')
  }

  const openAdd = () => setIsModal(prevState => ({...prevState, add: true}))

  const openEdit = () => setIsModal(prevState => ({...prevState, edit: true}))

  const openOrigin = () => setIsModal(prevState => ({...prevState, origin: true}))

  const openDest = () => setIsModal(prevState => ({...prevState, dest: true}))

  const closeJob = () => setIsModal(prevState => ({...prevState, origin: false, dest: false}))

  const toggleHistory = () => setIsModal(prevState => ({...prevState, history: isModal.history ? false : true}))

  const closeModal = () => {

    if (isChanged.current) {
      if (window.confirm('You have unsaved data. Proceed?')) {
        setIsModal(prevState => ({...prevState, add: false, edit: false}))
        clearIsValidated()
        isChanged.current = false
      }
    } else {
      setIsModal(prevState => ({...prevState, add: false, edit: false}))
      clearIsValidated()
    }

  }

  const selectOrigin = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[2].textContent === 'Home' && (td[0].textContent === '' || td[1].textContent === '')) {

      alert('You must first set a Home Address in Profile. Top right corner.')

    } else if (td[0].textContent === '' || td[1].textContent === '') {
      //lat = 33.84789303492371 // albus office
      //lng = -117.86229291534426
      alert('This job is missing a Latitude and Longitude. Contact an Admin.')
    } else {

      setIsValidated(prevState =>
        ({...prevState,
          originLat: td[0].textContent,
          originLng: td[1].textContent,
          origin: td[2].textContent
        })
      )
      closeJob()

    }

  }

  const selectDest = (e) => {

    let tr = e.target.parentNode
    let td = tr.getElementsByTagName('td')

    if (td[2].textContent === 'Home' && (td[0].textContent === '' || td[1].textContent === '')) {

      alert('You must first set a Home Address in Profile. Top right corner.')

    } else if (td[0].textContent === '' || td[1].textContent === '') {
      //lat = 33.84789303492371 // albus office
      //lng = -117.86229291534426
      alert('This job is missing a Latitude and Longitude. Contact an Admin.')
    } else {

      setIsValidated(prevState =>
        ({...prevState,
          destLat: td[0].textContent,
          destLng: td[1].textContent,
          dest: td[2].textContent
        })
      )
      closeJob()

    }

  }

  // function displayRoute(origin, destination, service, display) {
  //   service.route(
  //     {
  //       origin: origin,
  //       destination: destination
  //       // ,
  //       // waypoints: [
  //       //   { location: "Adelaide, SA" },
  //       //   { location: "Broken Hill, NSW" },
  //       // ],
  //       travelMode: google.maps.TravelMode.DRIVING,
  //       avoidTolls: true,
  //     },
  //     (result, status) => {
  //       if (status === "OK" && result) {
  //         display.setDirections(result);
  //       } else {
  //         alert("Could not display directions due to: " + status);
  //       }
  //     }
  //   );
  // }

  function computeTotalDistance(result) {
    // let distance = 0
    // let duration = 0
    // const myRoute = result.routes[0];
    //
    // if (!myroute) {
    //   return;
    // }

    // for (let i = 0; i < myroute.legs.length; i++) {
    //   distance += myRoute.legs[i].distance.value;
    //   duration += myRoute.legs[i].duration.value;
    // }

    setIsValidated(prevState => ({...prevState,
      distance: result.routes[0].legs[0].distance.value, // 1609.34, // converstion from meter to mile
      duration: result.routes[0].legs[0].duration.value // 3600 // seconds to hrs
    }))
  }

  function calculateAndDisplayRoute(directionsService, directionsRenderer) {

    //console.log(`isValidated: ${JSON.stringify(isValidated)}`)

    directionsService.route(
      {
        origin: {
          query: `${isValidated.originLat},${isValidated.originLng}`, //'tustin, ca', //document.getElementById("start").value,
        },
        destination: {
          query: `${isValidated.destLat},${isValidated.destLng}`, //'santa ana, ca', //document.getElementById("end").value,
        },
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (response, status) => {
        if (status === "OK") {
          directionsRenderer.setDirections(response);

          //console.log(`response: ${JSON.stringify(response)}`)
          //console.log(`distance: ${response.routes[0].legs[0].distance.value}`)
          //console.log(`duration: ${response.routes[0].legs[0].duration.value}`)

          setIsValidated(prevState => ({...prevState,
            // origin: fetchedData[i].origin,
            // dest: fetchedData[i].dest,
            // originLat: fetchedData[i].originLat,
            // originLng: fetchedData[i].originLng,
            // destLat: fetchedData[i].destLat,
            // destLng: fetchedData[i].destLng,
            distance: response.routes[0].legs[0].distance.value, // 1609.34, // converstion from meter to mile
            duration: response.routes[0].legs[0].duration.value // 3600 // seconds to hrs
          }))


        } else {
          window.alert("Directions request failed due to " + status);
        }
      }
    );
  }

  // function initMap(){
  //   navigator.geolocation.getCurrentPosition(success, error);
  // }
  //
  // function success(pos) {
  //     var crd = pos.coords;
  //     latitud = crd.latitude
  //     longitud = crd.longitude
  //     document.getElementById("latitud").innerHTML = latitud
  //     document.getElementById("longitud").innerHTML = longitud
  //     map = new google.maps.Map(document.getElementById("map"), {
  //         center: {lat: latitud, lng: longitud},
  //         zoom: 14
  //     });
  // };

    function initMap() {

      let google = googleRef.current

      const directionsService = new window.google.maps.DirectionsService();
      const directionsRenderer = new window.google.maps.DirectionsRenderer({
        draggable: true
      });
      directionsRenderer.addListener("directions_changed", () => {
        computeTotalDistance(directionsRenderer.getDirections());
      });

      let lat = 33.84789303492371 // albus office
      let lng = -117.86229291534426


      let map = new window.google.maps.Map(google, {
        mapTypeId: window.google.maps.MapTypeId.ROADMAP,
        zoom: 15,
        center: { lat: lat, lng: lng },
        tilt: 0
      });
      directionsRenderer.setMap(map);

      if (isValidated.origin !== '' && isValidated.origin !== null && isValidated.dest !== '' && isValidated.dest !== null) calculateAndDisplayRoute(directionsService, directionsRenderer)

  }

  let listOfData = fetchedData.map((data, i) => {

    let origin = data.origin === null ? '' : data.origin
    let dest = data.dest === null ? '' : data.dest
    let distance
    let gDistance = `${Math.round(Number(data.distance) / 1609.34 * 10) / 10} (G)`
    let duration = data.duration === null || data.duration === '' ? '' : Math.round(Number(data.duration) / 3600 * 10) / 10
    let displayDate
    let entryTime = data.entrytime === null ? '' : isModal.history ? formatDateTime(data.entrytime, true) : formatTime(data.entrytime, true)
    let mileageDate = data.mileageDate === null ? '' : isModal.history ? formatDateYMD(data.mileageDate, true) : 'No time'

    if (data.originOD !== '' && data.originOD !== null && data.destOD !== '' && data.destOD !== null) {
      distance = `${Number(data.destOD) - Number(data.originOD)} (O) ${gDistance}`
    } else {
      distance = data.distance === null & data.distance === '' ? '' : gDistance
    }

    if (mileageDate !== '' && mileageDate !== null) {
      displayDate = `${mileageDate} (!)`
    } else {
      displayDate = entryTime
    }

    if (
      (isModal.history || (!isModal.history && formatDateYMD(data.entrytime, true) === formatDateYMD(new Date()))) &&
      (searchValue === '' ||
      origin.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      dest.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      distance.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      duration.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      mileageDate.toString().toLowerCase().indexOf(searchValue.toLowerCase()) >= 0 ||
      entryTime.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0)

    ) {
      return (
        <tr key={data.id.toString()} onClick={selectRow}>
          <td style={{display: 'none'}}>{i}</td>
          <td>{origin}</td>
          <td>{dest}</td>
          <td>{distance}</td>
          <td>{duration}</td>
          <td>{displayDate}</td>
        </tr>
      )
    }

  })

  const showInfo = () => alert('When you save a route, I will save the distance and duration of that route. However, due to legal reasons, I cannot save the blue path shown on Google Maps. Therefore, the blue path may appear different when you view later, because Google will show the fastest route at that time. This is ok, I have already saved the important information.')

  let modalContent = (

    <div style={{width: '100%', height: '100%'}}>

      {isModal.edit ?  <ViewLocation data={isValidated} /> : null}

      <div style={{display: 'flex', flexFlow: 'column', alignItems: 'center', width: '100%', height: '100%', overflow: 'auto'}}>

        <div style={{margin: 10, flex: '0 1 auto', width: 'calc(100% - 20px)', overflow: 'auto'}}>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <Icon name='search' onClick={openOrigin} />
            <span>
              {
                isValidated.origin !== '' && isValidated.origin !== null ?
                `${isValidated.origin} (Origin)` : 'Select an Origin'
              }
            </span>
          </div>

          <div style={{display: 'flex', alignItems: 'center'}}>
            <Icon name='search' onClick={openDest} />
            <span>
              {
                isValidated.dest !== '' && isValidated.dest !== null ?
                `${isValidated.dest} (Destination)` : 'Select a Destination'
              }
            </span>
          </div>

          <div>
            <label style={{marginRight: 5}}>Date</label>
            <input className='input' type="date" pattern=".{1,}" name='mileageDate' onInput={validate} onChange={changedData} defaultValue={isValidated.mileageDate === null ? '' : formatDateYMD(isValidated.mileageDate, true)} required />
            <small>Optional</small>
          </div>

          <div>
            <label style={{marginRight: 5}}>Start Odometer</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='originOD' onInput={validate} onChange={changedData} placeholder='Optional' defaultValue={isValidated.originOD} required />
          </div>

          <div>
            <label style={{marginRight: 5}}>End Odometer</label>
            <input style={{width: 75}} className='input' type="text" pattern="\d{1,}(\.\d{1})?" name='destOD' onInput={validate} onChange={changedData} placeholder='Optional' defaultValue={isValidated.destOD} required />
          </div>

          <div style={{marginTop: 10, textAlign: 'center'}}>
            <label>Description</label>
            <textarea className='textArea' pattern="[a-zA-Z0-9]{1,}" name='description' onInput={validate} onChange={changedData} placeholder='Optional' defaultValue={isValidated.description} required></textarea>
          </div>

        </div>

        <div style={{display: 'flex', alignItems: 'center'}}>
          <Icon name='info' color='tomato' onClick={showInfo} />
          <span style={{color: 'tomato'}}>Not your route?</span>
        </div>

        <div style={{margin: 10, flex: '2', width: 'calc(100% - 20px)', overflow: 'auto'}}>
          <div style={{height: '100%', width: '100%'}} ref={googleRef}></div>
        </div>

      </div>

    </div>

  )

  return (
    <>
      {isModal.add || isModal.edit ? <Modal add={isModal.add ? addMileage : isModal.edit ? editMileage : null} delete={isModal.edit ? deleteMileage : null} content={modalContent} closeModal={closeModal} /> : null}
      {isModal.origin || isModal.dest ? <Job user={props.user} selectJob={isModal.origin ? selectOrigin : selectDest} closeModal={closeJob} mileage={true} /> : null}

      <div style={{display: 'flex', width: '100%', height: '100%'}}>

        <div style={{margin: 10, flex: '1 0 auto'}}>

          <div style={{display: 'flex', flexFlow: 'column', height: '100%'}}>

            <div>
              {props.user.device === 'desktop' ? <Icon name='add_circle' onClick={openAdd} /> : <AddButton onClick={openAdd} />}
              <Icon name='refresh' onClick={fetchData} />
              <Icon name='history' color={isModal.history ? 'dodgerblue' : 'gray'} onClick={toggleHistory} />
            </div>

            <SearchBar search={search} clearSearch={clearSearch} />

            {fetchedData.length > 0 ?

              <>

                <div style={{textAlign: 'center'}}><small>(O) Odometer, (G) Per Google, (!) selected Date</small></div>

                <div style={{margin: 10, flex: '1', overflow: 'auto'}}>

                <table>

                  <thead>
                    <tr>
                      <th>Origin</th>
                      <th>Dest</th>
                      <th>Distance (mi)</th>
                      <th>Duration (hr)</th>
                      <th>Time</th>
                    </tr>
                  </thead>

                  <tbody>
                    {listOfData}
                  </tbody>

                </table>

                </div>

              </> :
              <p style={{margin: 10}}>No mileage added.</p>
            }

          </div>

        </div>

      </div>

    </>
  )

}

export default Mileage
